import styled from 'styled-components'

export default styled.div`
  display: flex;
  font-family: 'Prompt', sans-serif;
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #979797;
  &.primary {
    color: ${(props) => props.theme.jadeGreen};
  }
  &.danger {
    color: ${(props) => props.theme.redFont};
  }
  &.btn-mode {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`
