import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import TextButtonStyled from './styledComponent'

class TextButton extends PureComponent {
  getClassName() {
    let className = this.props.className ? this.props.className : ''
    if (this.props.primary) {
      className += ' primary'
    } else if (this.props.danger) {
      className += ' danger'
    }
    if (this.props.onClick) {
      className += ' btn-mode'
    }
    return className
  }

  handleClicked() {
    this.props.onClick && this.props.onClick()
  }

  render() {
    return (
      <TextButtonStyled id={this.props.id} className={this.getClassName()} onClick={() => this.handleClicked()}>
        {this.props.label}
      </TextButtonStyled>
    )
  }
}

TextButton.defaultProps = {
  danger: false,
  primary: false
}

TextButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]).isRequired,
  danger: PropTypes.bool,
  primary: PropTypes.bool,
  onClick: PropTypes.func
}

export default TextButton
